import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import Config, { MultiLang } from '../config';
import Credits from '../credits/Credits';
import CreditsXoopsPathRedirect from '../credits/CreditsXoopsPathRedirect';
import Database from '../database/Database';
import DatabaseXoopsPathRedirect from '../database/DatabaseXoopsPathRedirect';
import MediaWiki from '../mediawiki/MediaWiki';
import MediaWikiXoopsPathRedirect from '../mediawiki/MediaWikiXoopsPathRedirect';
import Pico from '../pico/Pico';
import XoopsPathRedirect from './XoopsPathRedirect';

interface Props {
    lang: MultiLang;
}

const MainContent = (props: Props) => {
    const { lang } = props;
    return (
        <div className="mainContent">
            <Switch>
                <Route path="/database" render={() => <Database {...props} />} />
                <Route path="/credits" render={() => <Credits lang={lang} />} />
                {Config.PICO_MODULES.map((name) =>
                    <Route key={name} path={'/' + name} render={() => <Pico lang={lang} name={name} />} />
                )}
                <Route exact path="/mediawiki" render={() => {
                    const name = lang === 'en' ? 'Main_Page' : 'メインページ';
                    return <Redirect to={'/mediawiki/' + name} />;
                }} />
                <Route path="/mediawiki/:name" render={(props: RouteComponentProps<{ name: string }>) => {
                    const { match } = props;
                    const { params } = match;
                    const name = params.name;
                    return <MediaWiki lang={lang} name={name} />;
                }} />
                <Route path="/modules/xoonips" render={() => <DatabaseXoopsPathRedirect lang={lang} />} />
                <Route path="/modules/credits" render={() => <CreditsXoopsPathRedirect lang={lang} />} />
                {Config.PICO_MODULES.map((name) =>
                    <Route key={name} path={'/' + name} render={() => <Pico lang={lang} name={name} />} />
                )}
                <Route path="/modules/mediawiki" render={() => <MediaWikiXoopsPathRedirect lang={lang} />} />

                <Route component={XoopsPathRedirect} />
            </Switch>
        </div>
    );
}

export default MainContent;
