import React from 'react';
import { Route, Switch } from 'react-router';
import { Timeline } from 'react-twitter-widgets';
import { MultiLang } from '../config';
import CreditsMenu from '../credits/block/CreditsMenu';
import IndexTree from '../database/blocks/IndexTree';
import Functions from '../functions';
import MainMenu from './blocks/MainMenu';
import SelectLang from './blocks/SelectLang';

interface Props {
    lang: MultiLang;
}

const LeftColumn = (props: Props) => {
    const { lang } = props;
    return (
        <div className="leftcolumn">
            <div className="block plate">
                <div className="blockContent">
                    <SelectLang lang={lang} />
                </div>
            </div>
            <div className="block plate">
                <div className="blockTitle">{Functions.mlang('[en]Menu[/en][ja]メニュー[/ja]', lang)}</div>
                <div className="blockContent">
                    <MainMenu lang={lang} />
                </div>
            </div>
            <div className="block plate">
                <div className="blockTitle">{Functions.mlang('[en]Site Information[/en][ja]ウェブサイト情報[/ja]', lang)}</div>
                <div className="blockContent">
                    <CreditsMenu lang={lang} showAboutUs={false} />
                </div>
            </div>
            <Switch>
                <Route exact path="/" render={() =>
                    <div className="block plate">
                        <div className="blockTitle">Twitter</div>
                        <div className="blockContent">
                            <Timeline dataSource={{ sourceType: 'profile', screenName: 'DynamicBrainPF' }} options={{ height: '600', lang: lang }} />
                        </div>
                    </div>
                } />
                <Route path="/database" render={() =>
                    <div className="block plate">
                        <div className="blockTitle">{Functions.mlang('[en]Index Tree[/en][ja]インデックスツリー[/ja]', lang)}</div>
                        <div className="blockContent">
                            <IndexTree lang={lang} />
                        </div>
                    </div>
                } />
            </Switch>
        </div>
    );
}

export default LeftColumn;
