import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import imageStar from '../assets/images/star.gif';
import rankings from '../assets/rankings.json';
import ItemUtil, { ItemCore } from '../lib/ItemUtil';
import styles from './Rankings.module.css';

interface Props {
    lang: MultiLang;
}

const orderLabel = (order: number, lang: string) => {
    return String(order) + (lang === 'en' ? Functions.ordinal(order) : '位')
}

const Rankings = (props: Props) => {
    const items = [
        {
            title: Functions.mlang('[en]Frequently accessed items[/en][ja]頻繁に閲覧されるアイテム[/ja]', props.lang),
            list: rankings.accessed.map((item, idx) => {
                const url = ItemUtil.getUrl(item as ItemCore);
                const title = Functions.mlang(item.title, props.lang);
                const order = orderLabel(idx + 1, props.lang);
                return (
                    <div key={idx} className={styles.item}>
                        <div className={styles.order}>{order}</div>
                        <div className={styles.title}><Link to={url} title={title}>{title}</Link></div>
                        {idx === 0 && <div className={styles.star}><img src={imageStar} alt={order} /></div>}
                    </div>
                );
            }),
        },
        {
            title: Functions.mlang('[en]Frequently downloaded items[/en][ja]頻繁にダウンロードされるアイテム[/ja]', props.lang),
            list: rankings.downloaded.map((item, idx) => {
                const url = ItemUtil.getUrl(item as ItemCore);
                const title = Functions.mlang(item.title, props.lang);
                const order = orderLabel(idx + 1, props.lang);
                return (
                    <div key={idx} className={styles.item}>
                        <div className={styles.order}>{order}</div>
                        <div className={styles.title}><Link to={url} title={title}>{title}</Link></div>
                        {idx === 0 && <div className={styles.star}><img src={imageStar} alt={order} /></div>}
                    </div>
                );
            }),
        },
    ];
    return (
        <div>
            {items.map((item, idx) => {
                return (
                    <Fragment key={idx}>
                        {idx !== 0 && <hr />}
                        <h3 className={styles.heading}>{item.title}</h3>
                        <div>{item.list}</div>
                    </Fragment>
                );
            })}
        </div>
    );
}

export default Rankings;