import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MultiLang } from '../config';
import Welcome from '../custom/blocks/Welcome';
import Rankings from '../database/blocks/Rankings';
import RecentContents from '../database/blocks/RecentContents';
import Functions from '../functions';
import MainContent from './MainContent';

interface Props {
    lang: MultiLang;
}

const CenterBlocks = (props: Props) => {
    const { lang } = props;
    return (
        <>
            <div className="centerCcolumn">
                <div className="block">
                    <div className="blockContent">
                        <Welcome lang={lang} />
                    </div>
                </div>
            </div>
            <div className="clearfix plate">
                <div className="centerLcolumn">
                    <div className="block">
                        <div className="blockTitle">{Functions.mlang('[en]XooNIps Rankings[/en][ja]XooNIps ランキング[/ja]', lang)}</div>
                        <div className="blockContent">
                            <Rankings lang={lang} />
                        </div>
                    </div>
                </div>
                <div className="centerRcolumn">
                    <div className="block">
                        <div className="blockTitle">{Functions.mlang('[en]XooNIps Update[/en][ja]XooNIps アップデート[/ja]', lang)}</div>
                        <div className="blockContent">
                            <RecentContents lang={lang} />
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

const CenterColumn = (props: Props) => {
    const { lang } = props;

    return (
        <div className="centercolumn">
            <Switch>
                <Route exact path="/" render={() => <CenterBlocks lang={lang} />} />
                <Route render={() =>
                    <div className="block plate">
                        <MainContent lang={lang} />
                    </div>
                } />
            </Switch>
        </div>
    );
}

export default CenterColumn;