import { ItemConferenceSubTypes } from '../../lib/ItemUtil';
import TopBase, { TopBaseProps } from '../lib/TopBase';
import iconFile from '../../assets/images/icon_conference.gif';

class ConferenceTop extends TopBase {

    constructor(props: TopBaseProps) {
        super(props);
        this.type = 'conference';
        this.label = 'Conference';
        this.icon = iconFile;
        // DBPF:
        // this.description = '[en]Electrical presentation files for conference.[/en][ja]学会発表[/ja]';
        this.description = '[en]Presentations in conferences.[/en][ja]学術会議での発表[/ja]';
        this.subTypes = ItemConferenceSubTypes;
    }
}

export default ConferenceTop;