import TopBase, { TopBaseProps } from '../lib/TopBase';
import iconFile from '../../assets/images/icon_url.gif';

class UrlTop extends TopBase {

    constructor(props: TopBaseProps) {
        super(props);
        this.type = 'url';
        this.label = 'Url';
        this.icon = iconFile;
        // DBPF:
        // this.description = '[en]Link information.[/en][ja]関連リンク[/ja]';
        this.description = '[en]Related web pages.[/en][ja]関連Webページ[/ja]';
    }
}

export default UrlTop;