import React from 'react';
import NoticeSiteHasBeenArchived from '../../common/lib/NoticeSiteHasBeenArchived';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import { Link } from 'react-router-dom';

interface Props {
    lang: MultiLang;
}

const articles = {
    en: [
        {
            title: 'Minoru Tsukada, Memory and Learning mechanism in the Hippocampal Network',
            link: '/mediawiki/Memory_and_Learning_mechanism_in_the_Hippocampal_Network'
        },
        {
            title: 'Yoko Yamaguchi, Theta Rhythm and Memory Formation in Rat Hippocampus',
            link: '/mediawiki/Theta_Rhythm_and_Memory_Formation_in_Rat_Hippocampus'
        },
        {
            title: 'Hatsuo Hayashi, Dynamical features of neurons and the brain: Chaos, synchronization, and propagation',
            link: '/mediawiki/Dynamical_features_of_neurons_and_the_brain:_Chaos,_synchronization,_and_propagation'
        },
        {
            title: 'Ichiro Tsuda, Computational Life Science',
            link: '/mediawiki/Computational_Life_Science'
        },
        {
            title: 'Yutaka Sakai, From synapse to behavior',
            link: '/mediawiki/From_synapse_to_behavior'
        },
    ],
    ja: [
        {
            title: '塚田稔「学習と記憶の理論的・実験的研究」',
            link: '/mediawiki/学習と記憶の理論的・実験的研究'
        },
        {
            title: '山口陽子「ラット海馬のシータリズムと記憶形成」',
            link: '/mediawiki/ラット海馬のシータリズムと記憶形成'
        },
        {
            title: '林初男「ニューロンと脳のダイナミクス：カオス、同期、伝搬」',
            link: '/mediawiki/ニューロンと脳のダイナミクス：カオス、同期、伝搬'
        },
        {
            title: '津田一郎 「Computational Life Science」',
            link: '/mediawiki/Computational_Life_Science'
        },
        {
            title: '酒井裕「シナプスから行動へ」 ',
            link: '/mediawiki/From_synapse_to_behavior'
        },
    ]
};

const Welcome = (props: Props) => {
    const { lang } = props;
    return (
        <div className="welcome">
            <NoticeSiteHasBeenArchived lang={lang} />            
            <p className="concept">{Functions.mlang('[en]This site promotes studies on the dynamic principles of brain functions through unifying experimental and computational approaches in cellular, local circuit, global network and behavioral levels. Our goal is to capture the autonomy and the creativity in living organisms which enlightens the complexity of nature and society.[/en][ja]当サイトでは、脳の動的原理を解明することを目的として、細胞、局所回路、脳全域、行動などの様々なレベルでの実験的計算論的アプローチの統合的な研究を推進します。これらの研究から生命の自律性と情報創成の原理を捉え、さらに自然や人間社会の複雑性の理解を進めることを目指します。[/ja]', lang)}</p>
            <h3>{Functions.mlang('[en]Feature Articles[/en][ja]特集記事[/ja]', lang)}</h3>
            <ul className="articles">
                {articles[lang].map((i) => {
                    return <li key={i.title} className="article"><Link to={i.link}><span className="title">{i.title}</span></Link></li>;
                })}
            </ul>
            <h3>{Functions.mlang('[en]Announcements[/en][ja]お知らせ[/ja]', lang)}</h3>
            <p className="announce">
                <Link to={lang === 'en' ? '/hackathon/nix-odml2017_eng.html' : '/hackathon/nix-odml2017.html'}>NIX-odML Global Workshop &amp; Hackathon 2017 in Japan　({Functions.mlang('[en]Sept. 25-28, 2017[/en][ja]2017年9月25-28日[/ja]', lang)})</Link>
            </p>
        </div>
    );
}

export default Welcome;