import { ItemToolSubTypes } from '../../lib/ItemUtil';
import TopBase, { TopBaseProps } from '../lib/TopBase';
import iconFile from '../../assets/images/icon_tool.gif';

class ToolTop extends TopBase {

    constructor(props: TopBaseProps) {
        super(props);
        this.type = 'tool';
        this.label = 'Tool';
        this.icon = iconFile;
        // DBPF:
        // this.description = '[en]Tool programs/scripts.[/en][ja]データ解析用プログラム/スクリプト[/ja]';
        this.description = '[en]Tools for non-specific models or data.[/en][ja]特定のモデルやデータに限定しない汎用ツール[/ja]';
        this.subTypes = ItemToolSubTypes;
    }
}

export default ToolTop;