import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import imageStar from '../assets/images/star.gif';
import contents from '../assets/recent-contents.json';
import ItemUtil, { ItemCore } from '../lib/ItemUtil';
import styles from './RecentContents.module.css';

interface Props {
    lang: MultiLang;
}

const RecentContents = (props: Props) => {
    const { lang } = props;
    const list = contents.map((item, idx) => {
        const url = ItemUtil.getUrl(item as ItemCore);
        const title = Functions.mlang(item.title, lang);
        const date = moment(new Date(item.last_update_date * 1000)).format('Y/M/D');
        const order = String(idx + 1) + (lang === 'en' ? Functions.ordinal(idx + 1) : '位');
        return (
            <div key={idx} className={styles.item}>
                <div className={styles.order}>{order}</div>
                <div className={styles.title}><Link className={styles.title} to={url} title={title}>{title}</Link></div>
                <div className={styles.date}> ({date})</div>
                {idx === 0 && <div className={styles.star}><img src={imageStar} alt={order} /></div>}
            </div>
        );
    });
    return (
        <div>
            <h3 className={styles.heading}>{Functions.mlang('[en]Newly Arrived Items[/en][ja]新着アイテム[/ja]', lang)}</h3>
            <div>{list}</div>
        </div>
    );
}

export default RecentContents;