import { MultiLang } from '../../config';
import Functions from '../../functions';
import creditsJson from '../assets/credits.json';

export interface CreditsPageData {
    pid: number;
    title: string;
    content: string;
    lastupdate: number;
}

interface CreditsData {
    organization: string;
    pages: CreditsPageData[];
}

export interface CreditsMenu {
    title: string;
    link: string;
}

class CreditsUtils {
    private data: CreditsData;

    constructor(json: CreditsData) {
        this.data = json;
    }

    getTitle(lang: MultiLang): string {
        return Functions.mlang('[en]Site Information[/en][ja]サイト情報[/ja]', lang);
    }

    getIndexUrl(): string {
        return '/credits';
    }

    getPageUrl(pageId: number): string {
        return this.getIndexUrl() + '/' + pageId;
    }

    getMenu(showAboutUs: boolean): CreditsMenu[] {
        const menu: CreditsMenu[] = [];
        this.data.pages.forEach((page) => {
            menu.push({ title: page.title, link: this.getPageUrl(page.pid) });
        });
        if (showAboutUs) {
            menu.push({ title: this.getAboutUsTitle(), link: this.getIndexUrl() });
        }
        return menu;
    }

    getPage(pageId: number): CreditsPageData | null {
        const page = this.data.pages.find((page) => { return page.pid === pageId; });
        if (typeof page === 'undefined') {
            return null;
        }
        return page;
    }

    countPages(): number {
        return this.data.pages.length;
    }

    getAboutUsTitle(): string {
        return 'About Us';
    }

    getAboutUsContent(): string {
        return this.data.organization;
    }
}

export default new CreditsUtils(creditsJson);