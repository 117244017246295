import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Loading from '../common/lib/Loading';
import PageNotFound from '../common/lib/PageNotFound';
import XoopsCode from '../common/lib/XoopsCode';
import { MultiLang } from '../config';
import Functions from '../functions';
import MediaWikiUtils, { MediaWikiPageData } from './lib/MediaWikiUtils';
import './assets/style.css';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';

interface Props {
    lang: MultiLang;
    name: string;
}

interface State {
    loading: boolean;
    page: MediaWikiPageData | null;
}

class MediaWiki extends Component<Props, State> {

    private isActive: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            page: null,
        };
        this.isActive = false;
    }

    componentDidMount() {
        const { name } = this.props;
        this.isActive = true;
        this.updatePage(name);
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { name } = this.props;
        const prevName = prevProps.name;
        if (name !== prevName) {
            this.setState({ loading: true, page: null });
            this.updatePage(name);
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    updatePage(name: string) {
        this.isActive = true;
        MediaWikiUtils.getPage(name, (page: MediaWikiPageData | null) => {
            if (this.isActive) {
                this.setState({ loading: false, page });
            }
        })
    }

    render() {
        const { lang } = this.props;
        const { loading, page } = this.state;
        if (loading) {
            return <Loading />;
        }
        if (page === null) {
            return <PageNotFound lang={lang} />;
        }
        return (
            <div className="mediawiki">
                <Helmet>
                    <title>{Functions.mlang(page.title, lang)} - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <NoticeSiteHasBeenArchived lang={lang} />
                <div className="mw-header">
                    <div className="navigation">
                        <Link to="/mediawiki/メインページ?ml_lang=ja">メインページ (Japanese)</Link>
                        |
                        <Link to="/mediawiki/Main_Page?ml_lang=en">Main Page (English)</Link>
                    </div>
                </div>
                <hr />
                <div className="mw-body">
                    <h1 className="firstHeading">{Functions.mlang(page.title, lang)}</h1>
                    <div className="mw-content">
                        <div className="mw-content-text">
                            <XoopsCode lang={lang} text={page.text} dohtml={true} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MediaWiki;
