import axios from 'axios';
import loki from 'lokijs';
import contentsJson from '../assets/contents.json';
import Functions from '../../functions';

export interface MediaWikiPageData {
    title: string;
    text: string;
}

export interface MediaWikiContentData {
    id: number;
    title: string;
}

export type GetContentCallback = (page: MediaWikiPageData | null) => void;

class MediaWikiUtils {

    private database: loki;
    private contents: Collection<MediaWikiContentData>;

    constructor(json: MediaWikiContentData[]) {
        this.database = new loki('mediawiki');
        this.contents = this.database.addCollection<MediaWikiContentData>('contents');
        json.forEach((data) => {
            this.contents.insert(data);
        });
    }

    getPage(name: string, callback: GetContentCallback): void {
        const title = Functions.unescape(name).replace(/_/g, ' ');
        const content = this.contents.findOne({ title });
        if (content === null) {
            callback(null);
        } else {
            axios.get('/mediawiki/contents/' + content.id + '.json').then((response) => {
                const page = response.data as MediaWikiPageData;
                callback(page);
            }).catch(e => {
                callback(null);
            });
        }
    }
}

export default new MediaWikiUtils(contentsJson);