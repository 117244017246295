import React from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import iconFile from '../assets/images/icon_folder.gif';
import IndexUtil, { Index } from './IndexUtil';

interface Props {
    lang: MultiLang;
    index: Index;
}

const ListIndexItem = (props: Props) => {
    const { lang, index } = props;
    const url = IndexUtil.getUrl(index.id);
    const title = Functions.mlang(index.title, lang);
    const numOfIndexes = IndexUtil.countChildren(index.id);
    return (
        <table>
            <tbody>
                <tr>
                    <td className="listIcon">
                        <Link to={url}><img alt={title} src={iconFile} /></Link>
                    </td>
                    <td>
                        <Link to={url}>{title}</Link>
                        <br />
                        {numOfIndexes} indexes / {index.numOfItems} items
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

const DatabaseListIndex = (props: Props) => {
    const { lang } = props;
    const children = IndexUtil.getChildren(props.index.id);
    if (children.length === 0) {
        return null;
    }
    const treeList = children.map((value: Index, idx) => {
        const evenodd = idx % 2 === 0 ? 'even' : 'odd';
        return (
            <tr key={value.id}>
                <td className={evenodd}>
                    <ListIndexItem lang={lang} index={value} />
                </td>
            </tr >
        );
    });
    return (
        <table className="listTable">
            <tbody>
                {treeList}
            </tbody>
        </table>
    );
}

export default DatabaseListIndex;
