import { ItemSimulatorSubTypes } from '../../lib/ItemUtil';
import TopBase, { TopBaseProps } from '../lib/TopBase';
import iconFile from '../../assets/images/icon_simulator.gif';

class SimulatorTop extends TopBase {

    constructor(props: TopBaseProps) {
        super(props);
        this.type = 'simulator';
        this.label = 'Simulator';
        this.icon = iconFile;
        // DBPF:
        // this.description = '[en]Programs/scripts for simulation.[/en][ja]シミュレーション用プログラム/スクリプト[/ja]';
        this.description = '[en]Programs for specific simulations.[/en][ja]特定のシミュレーション用プログラム[/ja]';
        this.subTypes = ItemSimulatorSubTypes;
    }
}

export default SimulatorTop;