import React, { Component } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';

interface Props extends RouteComponentProps {
    lang: MultiLang;
}

class MediaWikiXoopsPathRedirect extends Component<Props> {

    getRedirectUrl(): string {
        const { location } = this.props;
        const name = 'mediawiki';
        const pathname = location.pathname || '';
        const search = new RegExp(`^/modules/${name}/(?:index.php/)?([^/]*)$`);
        const matches = pathname.match(search);
        if (matches === null) {
            return '';
        }
        const path = matches[1] || '';
        return '/' + name + '/' + path;
    }

    render() {
        const { lang } = this.props;
        const url = this.getRedirectUrl();
        if (url === '') {
            return <PageNotFound lang={lang} />;
        }
        return <Redirect to={url} />;
    }
}

export default withRouter(MediaWikiXoopsPathRedirect);
