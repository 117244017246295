import React, { ChangeEvent, Component, MouseEvent } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import imageJapanNode from '../common/assets/theme/images/icon_jnode.png';
import Config, { MultiLang } from '../config';
import ItemUtil from '../database/lib/ItemUtil';
import Functions from '../functions';
import LinkImage from './lib/LinkImage';

interface Props extends RouteComponentProps {
    lang: MultiLang;
}

interface State {
    keyword: string;
}

class Header extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = { keyword: '' };
    }

    handleChangeKeyword(event: ChangeEvent<HTMLInputElement>) {
        const keyword = event.target.value;
        this.setState({ keyword });
    }

    handleSubmit(event: MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        const keyword = this.state.keyword.trim();
        if (keyword !== '') {
            const url = ItemUtil.getSearchByKeywordUrl('all', this.state.keyword);
            this.props.history.push(url);
        }
    }

    render() {
        const { lang } = this.props;
        const title = Functions.mlang(Config.SITE_TITLE, lang);
        return (
            <div id="header" className="clearfix">
                <div className="left logo">
                    <Link to="/"><span className="hidden">{title}</span></Link>
                </div>
                <div className="right">
                    <input className="searchInput" type="text" value={this.state.keyword} onChange={this.handleChangeKeyword} />
                    <button className="searchButton" onClick={this.handleSubmit}>Search</button>
                    <LinkImage image={imageJapanNode} title="INCF Japan Node" url={'https://www.neuroinf.jp/'} />
                </div>
            </div>
        );
    }
}

export default withRouter(Header);