const SITE_TITLE = 'Dynamic Brain Platform';
const SITE_SLOGAN = 'Official Site';
const GOOGLE_ANALYTICS_TRACKING_ID = 'UA-23709948-1';
const XOONIPS_ITEMTYPES = ['model', 'data', 'tool', 'book', 'paper', 'conference', 'url', 'presentation', 'simulator', 'stimulus', 'memo', 'files', 'binder'];
const PICO_MODULES = ['documents', 'hackathon'];

export type MultiLang = 'en' | 'ja';

const Config = {
    SITE_TITLE,
    SITE_SLOGAN,
    GOOGLE_ANALYTICS_TRACKING_ID,
    XOONIPS_ITEMTYPES,
    PICO_MODULES,
}

export default Config;
