import React from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config.js';
import Functions from '../../functions';
import mainMenus from '../assets/main-menu.json';

interface Props {
    lang: MultiLang;
}

const MainMenu = (props: Props) => {
    const { lang } = props;
    const links = mainMenus.map((item, idx) => {
        const title = Functions.mlang(item.title, lang);
        const style = idx === 0 ? "menuTop" : "menuMain";
        const link = item.link.match(/^https?:\/\//) ? 
            <a className={style} href={item.link} target="_blank" rel="noopener noreferrer">{title}</a> :
            <Link className={style} to={item.link}>{title}</Link>;
        return <li key={idx}>{link}</li>
    });
    return (
        <ul className="mainmenu">
            {links}
        </ul>
    );
}

export default MainMenu;
